import { Button } from "@element/react-components";
import "../styles/Confirmation.scss";

const Confirmation = (props) => {
  return (
    <>
      <div className="custommodal">
        <div className="customsmallmodalbody">
          <h2 className="blackheading">Confirmation</h2>
          <p>{props.message}</p>
          {props.error !== undefined && props.error !== "" && (
            <p className="error">{props.error}</p>
          )}
          <div className="formsingleelm btnframeleft">
            <Button
              type="submit"
              label="CANCEL"
              variant="outlined"
              onClick={() => props.cancelfn()}
              style={{
                backgroundColor: "#fff",
                borderColor: "#000",
                color: "#000",
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <Button
              type="submit"
              label="OK"
              variant="outlined"
              onClick={() =>
                props.submitfn(props.type !== "" ? props.type : "")
              }
              style={{
                backgroundColor: "#40BAFF",
                borderColor: "#000",
                color: "#000",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
