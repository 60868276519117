import React, { useState, useRef } from "react";
import {
  FileUpload,
  Button,
  Select,
  Textfield,
} from "@element/react-components";
import axios from "axios";
import {
  basePath,
  httpHeadersUpload,
  rulesSetList,
  restrictionList,
  requirementList,
  markingStyleList,
  redactioninfoList,
  redactionVariantList,
} from "../utils/constants";
import "../styles/Uploadfile.scss";

const Uploadfile = (props) => {
  const fileUploadRef = useRef();
  const [file, setFile] = useState();
  const [showelements, setShowelements] = useState(false);
  const defaultInputValues = {
    docType: "",
    documentName: "",
    // uploadFileType: "",
    studyDrug: "",
    projectId: "",
    studyNumbers: "",
    redactionVariant: "",
    requirement: "",
    rulesSet: "",
    markingStyle: "",
    documentOwner: "",
    restriction: "",
  };
  const [inputvalues, setInputvalues] = useState(defaultInputValues);
  const docSchema = ["Clinical", "Crop Science", "Pharmaceuticals"];

  const filehandleChange = (val) => {
    setFile(val[0]);
  };

  const removeFile = () => {
    setFile(null);
    setShowelements(false);
    setInputvalues(defaultInputValues);
  };

  const settingError = (flag, type, message) => {
    props.settingerrorstatus({
      flag: flag,
      type: type,
      message: message,
    });
  };

  const handleChange = (value, type) => {
    if (type === "docType") {
      setInputvalues({ ...inputvalues, docType: value });
    } else if (type === "documentName") {
      setInputvalues({ ...inputvalues, documentName: value });
    } /*
    else if (type === "uploadFileType") {
      setInputvalues({ ...inputvalues, uploadFileType: value });
    }
    */ else if (type === "studyDrug") {
      setInputvalues({ ...inputvalues, studyDrug: value });
    } else if (type === "projectId") {
      setInputvalues({ ...inputvalues, projectId: value });
    } else if (type === "studyNumbers") {
      setInputvalues({ ...inputvalues, studyNumbers: value });
    } else if (type === "redactionVariant") {
      setInputvalues({ ...inputvalues, redactionVariant: value });
    } else if (type === "requirement") {
      setInputvalues({ ...inputvalues, requirement: value });
    } else if (type === "rulesSet") {
      setInputvalues({ ...inputvalues, rulesSet: value });
    } else if (type === "markingStyle") {
      setInputvalues({ ...inputvalues, markingStyle: value });
    } else if (type === "documentOwner") {
      setInputvalues({ ...inputvalues, documentOwner: value });
    } else if (type === "restriction") {
      setInputvalues({ ...inputvalues, restriction: value });
    }
    settingError(false, "", "");
  };

  const nextBtn = () => {
    if (inputvalues.docType !== "") {
      setShowelements(true);
      settingError();
    } else {
      props.settingerrorstatus({
        flag: true,
        type: "error",
        message: "Choose a value",
      });
    }
  };

  const cancelBtn = () => {
    setFile(null);
    setShowelements(false);
    setInputvalues(defaultInputValues);
  };

  const submitBtn = () => {
    if (inputvalues.documentName === "") {
      settingError(true, "error", "Enter document name");
    } /*
    else if (inputvalues.uploadFileType === "") {
      settingError(true, "error", "Enter type of file");
    }
    */ else if (inputvalues.studyDrug === "") {
      settingError(true, "error", "Enter study drug");
    } else if (inputvalues.projectId === "") {
      settingError(true, "error", "Enter Project Id");
    } else if (inputvalues.studyNumbers === "") {
      settingError(true, "error", "Enter study number");
    } else if (inputvalues.redactionVariant === "") {
      settingError(true, "error", "Select redaction variant");
    } else if (inputvalues.requirement === "") {
      settingError(true, "error", "Enter redaction requirement");
    } else if (inputvalues.rulesSet === "") {
      settingError(true, "error", "Enter rule set");
    } else if (inputvalues.markingStyle === "") {
      settingError(true, "error", "Enter marking style");
    } else if (inputvalues.restriction === "") {
      settingError(true, "error", "Enter restriction");
    } else {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("user", props.username);
      formData.append("documentType", inputvalues.docType);
      formData.append("documentName", inputvalues.documentName);
      // formData.append("uploadFileType", inputvalues.uploadFileType);
      formData.append("studyDrug", inputvalues.studyDrug);
      formData.append("projectId", inputvalues.projectId);
      formData.append("studyNumbers", inputvalues.studyNumbers);
      formData.append("redactionVariant", inputvalues.redactionVariant);
      formData.append("requirement", inputvalues.requirement);
      formData.append("rulesSet", inputvalues.rulesSet);
      formData.append("markingStyle", inputvalues.markingStyle);
      formData.append("documentOwner", inputvalues.documentOwner);
      formData.append("restriction", inputvalues.restriction);
      axios({
        url: `${basePath}/upload/`,
        method: "POST",
        headers: httpHeadersUpload,
        data: formData,
      })
        .then((res) => {
          settingError(true, "success", "Successfully uploaded");
          setFile(null);
          setShowelements(false);
          setInputvalues(defaultInputValues);
          props.loadDocumentListFn();
        })
        .catch((err) => {
          settingError(true, "error", `Error: Upload failed. Try again.`);
        });
    }
  };

  return (
    <div className="form_upload">
      <div className="form_section">
        <FileUpload
          label="Upload"
          disabled={false}
          ref={fileUploadRef}
          themeColor="primary"
          onChange={(e) => filehandleChange(e)}
          placeholder="No File Chosen"
        />
        {file?.name !== "" && file?.name !== undefined ? (
          <>
            <span className="uploadedfilename">{file?.name}</span>
            <button className="closebtn" onClick={() => removeFile()}>
              X
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      {file?.name !== "" && file?.name !== undefined ? (
        <div className="form_section">
          <Select
            dense
            id="docType"
            variant="outlined"
            options={docSchema}
            className="doctypeselect"
            value={inputvalues.docType}
            label="Choose Document type"
            onChange={(e) => handleChange(e, "docType")}
          />
          <button className="nextbtn" onClick={() => nextBtn()}>
            Next
          </button>
        </div>
      ) : (
        ""
      )}
      {showelements ? (
        <>
          <div className="form_section">
            <h2 className="blueheading">Upload Files</h2>
          </div>
          <div className="form_section margintop20">
            <div className="form_section_elements">
              <Textfield
                id="documentName"
                name="documentName"
                variant="outlined"
                label="Enter Document name*"
                style={{ width: "100%" }}
                value={inputvalues.documentName}
                placeholder="Enter document name"
                onChange={(e) => handleChange(e.target.value, "documentName")}
              />
            </div>
            <div className="form_section_elements">
              <Select
                dense
                variant="outlined"
                id="restriction"
                style={{ width: "100%" }}
                options={restrictionList}
                label="Select Restriction*"
                value={inputvalues.restriction}
                onChange={(e) => handleChange(e, "restriction")}
              />
            </div>
            <div className="form_section_elements">
              <Textfield
                id="studyDrug"
                name="studyDrug"
                variant="outlined"
                label="Enter Study drug(s)*"
                style={{ width: "100%" }}
                value={inputvalues.studyDrug}
                placeholder="Enter study drug"
                onChange={(e) => handleChange(e.target.value, "studyDrug")}
              />
            </div>
            <div className="form_section_elements">
              <Textfield
                id="projectId"
                name="projectId"
                variant="outlined"
                label="Enter Project Id*"
                style={{ width: "100%" }}
                value={inputvalues.projectId}
                placeholder="Enter Project Id"
                onChange={(e) => handleChange(e.target.value, "projectId")}
              />
            </div>
          </div>
          <div className="form_section">
            <div className="form_section_elements">
              <Textfield
                id="studyNumbers"
                name="studyNumbers"
                variant="outlined"
                label="Enter Study number(s)*"
                style={{ width: "100%" }}
                value={inputvalues.studyNumbers}
                placeholder="Enter Study number(s)"
                onChange={(e) => handleChange(e.target.value, "studyNumbers")}
              />
            </div>
            <div className="form_section_elements">
              <Select
                dense
                variant="outlined"
                id="redactionVariant"
                style={{ width: "100%" }}
                options={redactionVariantList}
                label="Select Redaction variant*"
                value={inputvalues.redactionVariant}
                onChange={(e) => handleChange(e, "redactionVariant")}
              />
            </div>
            <div className="form_section_elements">
              <Select
                dense
                variant="outlined"
                id="requirement"
                style={{ width: "100%" }}
                options={requirementList}
                label="Select Redaction Requirement*"
                value={inputvalues.requirement}
                onChange={(e) => handleChange(e, "requirement")}
              />
            </div>
            <div className="form_section_elements">
              <Select
                dense
                variant="outlined"
                id="rulesSet"
                style={{ width: "100%" }}
                options={rulesSetList}
                label="Select Applicable rule set(s)*"
                value={inputvalues.rulesSet}
                onChange={(e) => handleChange(e, "rulesSet")}
              />
            </div>
          </div>
          <div className="form_section textcenter">
            <div className="form_section_elements">
              <Select
                dense
                variant="outlined"
                id="markingStyleId"
                style={{ width: "100%" }}
                options={markingStyleList}
                label="Select Marking Style*"
                value={inputvalues.markingStyle}
                onChange={(e) => handleChange(e, "markingStyle")}
              />
            </div>
          </div>
          <div className="form_section textcenter uploadbtnframe">
            <div className="form_section_elements">
              <Button
                type="submit"
                label="Cancel"
                variant="outlined"
                onClick={() => cancelBtn()}
              />
              <Button
                type="submit"
                label="Submit"
                variant="outlined"
                onClick={() => submitBtn()}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Uploadfile;
