import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "@element/react-components";

// import MenuItem from "@mui/material/MenuItem";
// import Menu from "@mui/material/Menu";

export const SignInButton = ({ radioValue }) => {
  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogin = (loginType) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      /**
       * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
       * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
       * For more information, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
       */
      instance.loginPopup({
        ...loginRequest,
        redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
      });
    } else if (loginType === "redirect") {
      // sessionStorage.removeItem('msal.interaction.status')
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <>
      <div>
        <Button
          buttonSize="themeDefault"
          themeColor="secondary"
          label="SIGN IN WITH SINGLE SIGN-ON(SSO)"
          variant="filled"
          onClick={(e) => handleLogin("redirect")}
          disabled={radioValue === "disagree"}
          style={{ marginBottom: "5px" }}
        />
        {/* <button onClick={() => handleLogin("redirect")} key="loginRedirect">Sign in using Redirect</button> */}
      </div>
    </>
  );
};
