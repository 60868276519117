import React, { useState, useEffect } from "react";
import { Button, Select, Textfield } from "@element/react-components";
import {
  basePath,
  httpHeaders,
  rulesSetList,
  restrictionList,
  requirementList,
  markingStyleList,
  redactioninfoList,
  redactionVariantList,
} from "../utils/constants";
import Loader from "./Loader";
import axios from "axios";

const Editdocuments = (props) => {
  const [loader, setLoader] = useState(false);
  const [ipvalues, setIpvalues] = useState({
    documentId: 0,
    documentName: "",
    documentOwner: "",
    uploadFileType: "",
    projectId: "",
    redactionVariant: "",
    requirement: "",
    restriction: "",
    rulesSet: "",
    studyDrug: "",
    markingStyle: "",
    studyNumbers: "",
    modifiedBy: "",
  });
  const [ipnames, setIpnames] = useState([]);

  const handleChange = (value, type) => {
    if (type === "documentName") {
      if (ipnames.indexOf("Document name") === -1) {
        setIpnames([...ipnames, "Document name"]);
      }
      setIpvalues({ ...ipvalues, documentName: value });
    } else if (type === "uploadFileType") {
      if (ipnames.indexOf("File type") === -1) {
        setIpnames([...ipnames, "File type"]);
      }
      setIpvalues({ ...ipvalues, uploadFileType: value });
    } else if (type === "studyDrug") {
      if (ipnames.indexOf("Study drug") === -1) {
        setIpnames([...ipnames, "Study drug"]);
      }
      setIpvalues({ ...ipvalues, studyDrug: value });
    } else if (type === "projectId") {
      if (ipnames.indexOf("Project ID") === -1) {
        setIpnames([...ipnames, "Project ID"]);
      }
      setIpvalues({ ...ipvalues, projectId: value });
    } else if (type === "studyNumbers") {
      if (ipnames.indexOf("Study numbers") === -1) {
        setIpnames([...ipnames, "Study numbers"]);
      }
      setIpvalues({ ...ipvalues, studyNumbers: value });
    } else if (type === "redactionVariant") {
      if (ipnames.indexOf("Redaction variant") === -1) {
        setIpnames([...ipnames, "Redaction variant"]);
      }
      setIpvalues({ ...ipvalues, redactionVariant: value });
    } else if (type === "requirement") {
      if (ipnames.indexOf("Requirement") === -1) {
        setIpnames([...ipnames, "Requirement"]);
      }
      setIpvalues({ ...ipvalues, requirement: value });
    } else if (type === "rulesSet") {
      if (ipnames.indexOf("Rule set") === -1) {
        setIpnames([...ipnames, "Rule set"]);
      }
      setIpvalues({ ...ipvalues, rulesSet: value });
    } else if (type === "markingStyle") {
      if (ipnames.indexOf("Marking Style") === -1) {
        setIpnames([...ipnames, "Marking Style"]);
      }
      setIpvalues({ ...ipvalues, markingStyle: value });
    } else if (type === "documentOwner") {
      if (ipnames.indexOf("Document owner") === -1) {
        setIpnames([...ipnames, "Document owner"]);
      }
      setIpvalues({ ...ipvalues, documentOwner: value });
    } else if (type === "restriction") {
      if (ipnames.indexOf("Restriction") === -1) {
        setIpnames([...ipnames, "Restriction"]);
      }
      setIpvalues({ ...ipvalues, restriction: value });
    }
  };

  const submitBtn = () => {
    setLoader(true);
    const data = {
      modifiedBy: props.username,
      rulesSet: ipvalues.rulesSet,
      studyDrug: ipvalues.studyDrug,
      projectId: ipvalues.projectId,
      requirement: ipvalues.requirement,
      restriction: ipvalues.restriction,
      documentName: ipvalues.documentName,
      markingStyle: ipvalues.markingStyle,
      // uploadFileType: ipvalues.uploadFileType,
      studyNumbers: ipvalues.studyNumbers,
      redactionVariant: ipvalues.redactionVariant,
    };
    try {
      axios({
        url: `${basePath}/document/${props.editdata.documentId}/`,
        method: "PUT",
        headers: httpHeaders,
        data: data,
      })
        .then(function (response) {
          props.closePopup();
          if (response.status === 200) {
            props.loadDocumentListFn();
          }
          setLoader(false);
          props.settingerrorstatus({
            flag: true,
            type: "success",
            message: `${ipnames.join(", ")} successfully updated`,
          });
        })
        .catch((err) => {
          setLoader(false);
          props.closePopup();
          props.settingerrorstatus({
            flag: true,
            type: "error",
            message: `Error: ${err}`,
          });
        });
    } catch (error) {
      setLoader(false);
      props.closePopup();
      props.settingerrorstatus({
        flag: true,
        type: "error",
        message: `Error: ${error}`,
      });
    }
  };

  useEffect(() => {
    setIpvalues(props.editdata);
  }, []);

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="form_section">
        <h2 className="blueheading">Edit document</h2>
      </div>
      <div className="form_section margintop20">
        <div className="form_section_elements">
          <Textfield
            id="documentName"
            name="documentName"
            variant="outlined"
            label="Document name*"
            style={{ width: "100%" }}
            value={ipvalues.documentName}
            placeholder="Enter document name"
            onChange={(e) => handleChange(e.target.value, "documentName")}
          />
        </div>
        <div className="form_section_elements">
          {/*
          <Select
            dense
            variant="outlined"
            id="uploadFileTypeId"
            style={{ width: "100%" }}
            options={redactioninfoList}
            label="Select Redaction Info*"
            value={ipvalues.uploadFileType}
            onChange={(e) => handleChange(e, "uploadFileType")}
          />
          */}
          <Select
            dense
            variant="outlined"
            id="restriction"
            style={{ width: "100%" }}
            options={restrictionList}
            label="Restriction*"
            value={ipvalues.restriction}
            onChange={(e) => handleChange(e, "restriction")}
          />
        </div>
        <div className="form_section_elements">
          <Textfield
            id="studyDrug"
            name="studyDrug"
            variant="outlined"
            label="Study drug(s)*"
            style={{ width: "100%" }}
            value={ipvalues.studyDrug}
            placeholder="Enter study drug"
            onChange={(e) => handleChange(e.target.value, "studyDrug")}
          />
        </div>
        <div className="form_section_elements">
          <Textfield
            id="projectId"
            name="projectId"
            variant="outlined"
            label="Project Id*"
            style={{ width: "100%" }}
            value={ipvalues.projectId}
            placeholder="Enter value"
            onChange={(e) => handleChange(e.target.value, "projectId")}
          />
        </div>
      </div>
      <div className="form_section">
        <div className="form_section_elements">
          <Textfield
            id="studyNumbers"
            name="studyNumbers"
            variant="outlined"
            label="Study number(s)*"
            style={{ width: "100%" }}
            value={ipvalues.studyNumbers}
            placeholder="Enter value"
            onChange={(e) => handleChange(e.target.value, "studyNumbers")}
          />
        </div>
        <div className="form_section_elements">
          <Select
            dense
            variant="outlined"
            id="redactionVariant"
            style={{ width: "100%" }}
            options={redactionVariantList}
            label="Select Redaction variant*"
            value={ipvalues.redactionVariant}
            onChange={(e) => handleChange(e, "redactionVariant")}
          />
        </div>
        <div className="form_section_elements">
          <Select
            dense
            variant="outlined"
            id="requirement"
            style={{ width: "100%" }}
            options={requirementList}
            label="Select Redaction Requirement*"
            value={ipvalues.requirement}
            onChange={(e) => handleChange(e, "requirement")}
          />
        </div>
        <div className="form_section_elements">
          <Select
            dense
            variant="outlined"
            id="rulesSet"
            style={{ width: "100%" }}
            options={rulesSetList}
            label="Applicable rule set(s)*"
            value={ipvalues.rulesSet}
            onChange={(e) => handleChange(e, "rulesSet")}
          />
        </div>
      </div>
      <div className="form_section textcenter">
        <div className="form_section_elements">
          <Select
            dense
            variant="outlined"
            id="markingStyle"
            style={{ width: "100%" }}
            options={markingStyleList}
            label="Select Marking Style(s)*"
            value={ipvalues.markingStyle}
            onChange={(e) => handleChange(e, "markingStyle")}
          />
        </div>
      </div>
      <div className="form_section textcenter uploadbtnframe">
        <div className="form_section_elements">
          <Button
            type="submit"
            label="Cancel"
            variant="outlined"
            onClick={() => props.closePopup()}
          />
          <Button
            type="submit"
            label="Submit"
            variant="outlined"
            onClick={() => submitBtn()}
          />
        </div>
      </div>
    </>
  );
};

export default Editdocuments;
