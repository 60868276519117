// import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer";
import { useIsAuthenticated } from "@azure/msal-react";

export const PageLayout = (props) => {
  const isLoggedIn = useIsAuthenticated();

  return (
    <>
      <Header />
      <div className="greybg">{props.children}</div>
      {isLoggedIn && <Footer />}
    </>
  );
};
