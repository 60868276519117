import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Loader from "../components/Loader";
import { basePath, httpHeaders } from "../utils/constants";
import Highlightexample from "../components/Highlightexample";

const RedactionsAndReviews = () => {
  const [loader, setLoader] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [documentName, setDocumentName] = useState("");
  const [requirement, setRequirement] = useState("");
  const documentId = useParams().id;
  const lastIndex = basePath.lastIndexOf("/") || basePath.lastIndexOf("\\");
  const FILE_PATH = basePath.substring(0, lastIndex);

  const getDocument = async () => {
    setLoader(true);
    const docPath = `${basePath}/document/${documentId}/`;
    const data = await axios
      .get(docPath, { httpHeaders })
      .then((data) => data?.data);
    setCreatedAt(data?.createdAt);
    setDocumentName(data?.documentName);
    setRequirement(data?.requirement);
    setFileUrl(`${FILE_PATH}/media/${data?.filePath}`);
    setLoader(false);
  };

  useEffect(() => {
    getDocument();
  }, []);

  return (
    <>
      {fileUrl && (
        <Highlightexample
          fileUrl={fileUrl}
          setLoader={setLoader}
          createdAt={createdAt}
          documentId={documentId}
          requirement={requirement}
          documentName={documentName}
        />
      )}
      {loader ? <Loader /> : ""}
    </>
  );
};

export default RedactionsAndReviews;
