import { useEffect, useState } from "react";
import Dashboard from '../ui-components/Dashboard';
// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import { ProfileData } from "../ui-components/ProfileData";
import { Loading } from "../ui-components/Loading";
import { ErrorComponent } from "../ui-components/ErrorComponent";
import { callMsGraph } from "../utils/MsGraphApiCall";

// Material-ui imports
import Paper from "@mui/material/Paper";

const ProfileContent = () => {
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);

    // useEffect(() => {
    //     if (!graphData && inProgress === InteractionStatus.None) {
    //         callMsGraph().then(response => setGraphData(response)).catch((e) => {
    //             if (e instanceof InteractionRequiredAuthError) {
    //                 instance.acquireTokenRedirect({
    //                     ...loginRequest,
    //                     account: instance.getActiveAccount()
    //                 });
    //             }
    //         });
    //     }
    // }, [inProgress, graphData, instance]);
  
    return (
        <Paper>
            {/* { graphData ? <ProfileData graphData={graphData} /> : null } */}
           <Dashboard/>
        </Paper>
    );
};

export function Profile() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Popup} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={Loading}
        >
            <ProfileContent />
        </MsalAuthenticationTemplate>
      )
};