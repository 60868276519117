import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useGlobalFilter,
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useColumnOrder,
} from "react-table";
import { Link } from "react-router-dom";
import { Checkbox, Icon } from "@element/react-components";
import axios from "axios";

import Loader from "./Loader";
import Search from "../assets/Search.svg";
import upIcon from "../assets/up.svg";
import downIcon from "../assets/down.svg";
import {
  basePath,
  httpHeaders,
  statusList,
  modifiedPeriodList,
} from "../utils/constants";
import "../styles/Dashboardtable.scss";

const Dashboardtable = () => {
  const [loader, setLoader] = useState(false);
  const [tbldata, setTbldata] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [autocompletelist, setAutocompletelist] = useState(false);
  const [listofusers, setListofusers] = useState([]);
  const [autolistofusers, setAutolistofusers] = useState([]);
  const autolistRef = useRef(null);

  const defaultFilterVal = {
    status: [],
    timestamp: [],
    documentName: [],
    createdBy: [],
  };
  const [filterType, setFilterType] = useState(defaultFilterVal);
  const [filenametext, setFilenametext] = useState("");

  //  Table starts
  let tblcolumns = [
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Timestamp",
      id: "createdBy",
      displayType: "date",
      sortable: true,
      accessor: (e) => {
        return new Date(e.modifiedAt).toLocaleDateString();
      },
    },
  ];
  const columns = useMemo(() => tblcolumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    // getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data: tbldata,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useColumnOrder,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "documentName",
            header: "Document Name",
            Cell: ({ row }) => {
              return (
                <>
                  <Link to={`/reviews/${row.original.documentId}`}>
                    {row.original.documentName}
                  </Link>
                </>
              );
            },
          },
          ...columns,
        ];
      });
    }
  );
  const { pageIndex, pageSize } = state;
  //  Table ends

  // const loadUsers = async () => {
  //   setLoader(true);
  //   try {
  //     await axios
  //       .get(`${basePath}/get_users`, { httpHeaders })
  //       .then((response) => {
  //         setListofusers(response.data);
  //         setAutolistofusers(response.data);
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         setLoader(false);
  //       });
  //   } catch (error) {
  //     setLoader(false);
  //   }
  // };

  const loadDocumentList = () => {
    setLoader(true);
    fetch(`${basePath}/document/`, { httpHeaders })
      .then((response) => response.json())
      .then((json) => {
        let temp = [];
        json.map((itm) => temp.push(itm.documentName));
        setTbldata(json);
        setListofusers(temp);
        setAutolistofusers(temp);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const removeFromCheckbox = (type, value) => {
    if (type === "documentName") {
      let temp = filterType.documentName;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, documentName: newarr });
    }
    if (type === "timestamp") {
      let temp = filterType.timestamp;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, timestamp: newarr });
    }
    if (type === "status") {
      let temp = filterType.status;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, status: newarr });
    }
  };

  const addFilenames = (e) => {
    let temp = filterType.documentName;
    if (filenametext.length > 0 && e.key === "Enter") {
      temp.push(filenametext);
      setFilterType({ ...filterType, documentName: temp });
      setFilenametext("");
    }
  };

  const modalCheckboxClick = (flag, type, value) => {
    if (type === "status") {
      let temp = filterType.status;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, status: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, status: newarr });
      }
    }
    if (type === "timestamp") {
      let temp = filterType.timestamp;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, timestamp: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, timestamp: newarr });
      }
    }
  };

  const onCancel = () => {
    setFilterType(defaultFilterVal);
    loadDocumentList();
    setOpenModal(false);
  };

  const finalSubmit = () => {
    try {
      axios({
        url: `${basePath}/filter_documents/`,
        method: "POST",
        headers: httpHeaders,
        data: filterType,
      })
        .then(function (response) {
          setTbldata(response.data);
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const selectFilterUsers = (value) => {
    let temp = filterType.documentName;
    if (temp.indexOf(value) < 0) {
      temp.push(value);
      setFilterType({ ...filterType, documentName: temp });
    }
    setFilenametext("");
    setAutocompletelist(false);
  };

  const listUsersforFilter = (text) => {
    let temp = autolistofusers;
    const results = temp.filter((elm) => elm.toLowerCase().includes(text));
    setAutocompletelist(true);
    setListofusers(results);
    setFilenametext(text);
  };

  const handleClickOutside = (event) => {
    if (autolistRef.current && !autolistRef.current.contains(event.target)) {
      setAutocompletelist(false);
    }
  };

  useEffect(() => {
    loadDocumentList();
    // loadUsers();

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {openModal ? (
        <div className="custommodal">
          <div className="custommodalbody">
            <div className="cardtitle">
              <label>Filter</label>
            </div>
            <div className="documentfilter">
              <div className="documentfilterdiv">
                <div className="documentfiltersection">
                  <h3>Search File</h3>
                  <div className="searchtext">
                    <input
                      type="text"
                      value={filenametext}
                      placeholder="Search document name"
                      onChange={(e) => listUsersforFilter(e.target.value)}
                      onKeyDown={(e) => addFilenames(e)}
                      style={{
                        backgroundImage: `url(${Search})`,
                      }}
                    />
                    {autocompletelist ? (
                      <ul ref={autolistRef}>
                        {listofusers.map((item, index) => {
                          return (
                            <li
                              onClick={() => selectFilterUsers(item)}
                              key={`listofusers_${index}`}
                            >
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="checkboxvaluelist">
                    <ul>
                      {filterType.documentName.map((itm, i) => {
                        return (
                          <li key={`file_${i}`}>
                            &#10004; {itm}{" "}
                            <span
                              onClick={() =>
                                removeFromCheckbox("documentName", itm)
                              }
                            >
                              &#10006;
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="documentfilterdiv">
                <div className="documentfiltersection">
                  <h3>Select Last Modified</h3>
                  <div className="checkboxframes">
                    {modifiedPeriodList.map((perioditm, i) => {
                      return (
                        <Checkbox
                          key={`period_${i}`}
                          label={perioditm.value}
                          checked={
                            filterType.timestamp.indexOf(perioditm.value) < 0
                              ? false
                              : true
                          }
                          onChange={(e) =>
                            modalCheckboxClick(e, "timestamp", perioditm.value)
                          }
                        />
                      );
                    })}
                  </div>
                  <div className="checkboxvaluelist">
                    <ul>
                      {filterType.timestamp.map((itm, i) => {
                        return (
                          <li key={`timestamp_${i}`}>
                            &#10004; {itm}{" "}
                            <span
                              onClick={() =>
                                removeFromCheckbox("timestamp", itm)
                              }
                            >
                              &#10006;
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="documentfilterdiv">
                <div className="documentfiltersection">
                  <h3>Select Status</h3>
                  <div className="checkboxframes">
                    {statusList.map((statusItm, i) => {
                      return (
                        <Checkbox
                          key={`statchk_${i}`}
                          label={statusItm}
                          checked={
                            filterType.status.indexOf(statusItm) < 0
                              ? false
                              : true
                          }
                          onChange={(e) =>
                            modalCheckboxClick(e, "status", statusItm)
                          }
                        />
                      );
                    })}
                  </div>
                  <div className="checkboxvaluelist">
                    <ul>
                      {filterType.status.map((itm, i) => {
                        return (
                          <li key={`status_${i}`}>
                            &#10004; {itm}{" "}
                            <span
                              onClick={() => removeFromCheckbox("status", itm)}
                            >
                              &#10006;
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="filterbuttonframe buttonframe">
              <button onClick={() => onCancel()} className="white">
                Cancel
              </button>
              <button onClick={() => finalSubmit()} className="blue">
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="dashtable">
        <div className="cardhead">
          <div className="cardtitle">
            <label>My Recent files</label>
          </div>
          <div className="cardright">
            <button onClick={() => setOpenModal(true)} className="filterbtn">
              <Icon icon="filter_alt" iconSize="small" />
            </button>
          </div>
        </div>
        <div className="tableframe">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup, hgkey) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`hgrpPops_${hgkey}`}
                >
                  {headerGroup.headers.map((columns, togkey) => (
                    <th
                      {...columns.getHeaderProps(
                        columns.getSortByToggleProps()
                      )}
                      key={`togkey_${togkey}`}
                    >
                      <div className="th_head">
                        <div>{columns.render("header")}</div>
                        <div className="updownarrows">
                          {columns.isSorted ? (
                            columns.isSortedDesc ? (
                              <Icon
                                icon={
                                  <img alt="down" src={downIcon} width="10" />
                                }
                              />
                            ) : (
                              <Icon
                                icon={<img alt="up" src={upIcon} width="10" />}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, ri) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`row_${ri}`}>
                    {row.cells.map((cell, ci) => {
                      return (
                        <td {...cell.getCellProps()} key={`row_${ci}`}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationFrame">
          <span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"|<"}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">|"}
          </button>
        </div>
        {loader ? <Loader /> : ""}
      </div>
    </>
  );
};

export default Dashboardtable;
