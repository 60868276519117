import { useState } from "react";
import {
  Card,
  Radio,
  Group,
  CardBody,
  CardContent,
  // CardLeadingMedia,
  CardHorizontalGroup,
} from "@element/react-components";
import BayerLogin from "../assets/BayerLogin.jpg";
import RedactionLogo from "../assets/RedactionLogo.svg";
// import { loginRequest } from "../authConfig";
// import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Footer from "../ui-components/Footer";
import SignInSignOutButton from "../ui-components/SignInSignOutButton";
import "react-toastify/dist/ReactToastify.css";
import "../styles/loginBody.scss";

const LoginBody = () => {
  // const { instance, accounts } = useMsal();
  // const isLoggedIn = useIsAuthenticated();
  const [radioValue, setRadioValue] = useState("disagree");

  const changeHandler = (event) => {
    setRadioValue(event.target.value);
    // if (event.target.value === "agree") {
    //   instance.loginRedirect(loginRequest);
    // }
  };

  return (
    <>
      <Group className="logingreybg" secondaryAlign="stretch">
        <div
          className="card_img_media"
          style={{ backgroundImage: `url(${BayerLogin})` }}
        />
        <Card variant="default" height="auto">
          <CardHorizontalGroup rtl={false}>
            {/* <CardLeadingMedia
              ratio="square"
              mediaUrl={BayerLogin}
              width="50%"
            /> */}
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <CardBody typographyType="body2" style={{ paddingTop: "20px" }}>
                <Group
                  direction="vertical"
                  primaryAlign="center"
                  secondaryAlign="center"
                  style={{ padding: "10px", gap: "8px" }}
                >
                  <img src={RedactionLogo} className="redactlogocont" />
                  {/* <h2 className="login_heading">ANONYMED</h2> */}
                  <p className="logintxt_content">
                    The document owner or delegate performing the redaction is
                    responsible for reviewing the entire document for any missed
                    information that needs to be redacted as PPD (Protected
                    Personal Data). There are items that the tool cannot capture
                    and documents will always require an additional human review
                    as explained in the training provided on this tool. By using
                    this tool, you agree to check the full document for PPD if
                    you are the document owner or delegate.
                  </p>
                  <div className="blueradio">
                    <Radio
                      label="Agree"
                      value={"agree"}
                      name="radio-group"
                      checked={radioValue === "agree"}
                      onChange={changeHandler}
                    />
                    <Radio
                      label="Disagree"
                      name="radio-group"
                      value={"disagree"}
                      checked={radioValue === "disagree"}
                      onChange={changeHandler}
                    />
                  </div>
                  <SignInSignOutButton radioValue={radioValue} />
                </Group>
              </CardBody>
            </CardContent>
          </CardHorizontalGroup>
        </Card>
      </Group>
      <Footer />
    </>
  );
};

export default LoginBody;
