import { useState } from "react";
import { Modal, Button, Textfield } from "@element/react-components";
import "../styles/footer.scss";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const handleClickCTS = () => {
    setOpen(true);
  };
  const handleOnClose = () => {
    // close Modal when close animation begins
    setOpen(false);
  };
  const handleOnChange = () => {};
  return (
    <>
      <div className="footer">
        <button>Terms of Service</button>
        <button>Privacy Policy</button>
        <a href="mailto:madhuri.myneni@bayer.com;prithvi.prakash@bayer.com">
          Contact Us
        </a>
        <button>Version 3.7</button>
        <Modal
          id="basic"
          open={open}
          onClose={handleOnClose}
          modalSize="dialog"
          title="Contact Us"
          primaryButton={<Button>Submit</Button>}
        >
          <div className="footer_ctn">
            <Textfield
              value={name}
              variant="outlined"
              label="Enter first & last Name"
              onChange={handleOnChange}
              fullWidth={false}
            />
            <Textfield
              value={name}
              variant="outlined"
              label="Enter Email"
              onChange={handleOnChange}
              fullWidth={false}
            />
            <Textfield
              value={name}
              variant="filled"
              label="how can we help ?"
              onChange={handleOnChange}
              fullWidth={false}
              textarea={true}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Footer;
