import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@element/react-components";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  highlightPlugin,
  MessageIcon,
  // HighlightArea,
  // RenderHighlightContentProps,
  // RenderHighlightsProps,
  // RenderHighlightTargetProps,
} from "@react-pdf-viewer/highlight";
import { Card, Button } from "@element/react-components";
import {
  // PrimaryButton,
  Position,
  Tooltip,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";

import Navbarreviews from "./Navbarreviews";
import Sidebartabs from "./Sidebartabs";
import { basePath, httpHeaders } from "../utils/constants";
import { testHighlights as _testHighlights } from "../ui-components/test-highlights";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/selection-mode/lib/styles/index.css";
import "../styles/sidebar.scss";

const Highlightexample = ({
  fileUrl,
  setLoader,
  createdAt,
  documentId,
  requirement,
  documentName,
}) => {
  const [notes, setNotes] = useState([]);
  const [selectedlist, setSelectedlist] = useState([]);
  const [actionlist, setActionlist] = useState([]);
  const [redactiontype, setRedactiontype] = useState("PPD");
  const [undoredolist, setUndoredolist] = useState("none");
  const droplistRef = useRef(null);
  // let noteId = notes.length;
  // const [message, setMessage] = useState("");

  const noteEles = new Map();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: SelectionMode.Text,
  });

  const testHighlights = _testHighlights;
  const PRIMARY_PDF_URL = "https://browse.arxiv.org/pdf/1708.08021.pdf";
  const searchParams = new URLSearchParams(document.location.search);
  const initialUrl = searchParams.get("url") || PRIMARY_PDF_URL;
  const [highlights, setHighlights] = useState(
    testHighlights[initialUrl] ? [...testHighlights[initialUrl]] : []
  );
  const lastIndex = basePath.lastIndexOf("/") || basePath.lastIndexOf("\\");
  const FILE_PATH = basePath.substring(0, lastIndex);
  const documentIds = useParams().id;

  const getRedactions = () => {
    setLoader(true);
    axios({
      url: `${basePath}/redact_new`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentId: documentIds,
      },
    })
      .then((res) => {
        setLoader(false);
        setNotes(res.data);
        setActionlist([]);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const generatedocument = async () => {
    setLoader(true);
    await axios({
      url: `${basePath}/generateDocumentNew`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentPath: fileUrl,
        redactions: notes,
      },
    })
      .then((response) => {
        setLoader(false);
        fetch(FILE_PATH + "/" + response.data.docPath).then((resp) => {
          resp.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Result.pdf";
            alink.click();
          });
        });
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const proposeddocument = async () => {
    setLoader(true);
    await axios({
      url: `${basePath}/generateDocumentNew`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentPath: fileUrl,
        redactions: notes,
        version: "proposal",
      },
    })
      .then((response) => {
        setLoader(false);
        fetch(FILE_PATH + "/" + response.data.docPath).then((resp) => {
          resp.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Result.pdf";
            alink.click();
          });
        });
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onsave = async () => {
    setLoader(true);
    await axios({
      url: `${basePath}/save_redactions_new`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentId: documentId,
        redactions: notes,
      },
    })
      .then((response) => {
        getRedactions();
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const resethighlights = () => {
    setHighlights([]);
    setNotes([]);
  };

  const renderHighlightTarget = (props) => (
    <div
      style={{
        background: "#eee",
        display: "flex",
        position: "absolute",
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: "translate(0, 8px)",
        zIndex: 1,
      }}
    >
      <Tooltip
        position={Position.TopCenter}
        target={
          <Button onClick={props.toggle}>
            <MessageIcon />
          </Button>
        }
        content={() => <div style={{ width: "100px" }}>Add highlights</div>}
        offset={{ left: 10, top: -18 }}
      />
    </div>
  );

  const renderHighlightContent = (props) => {
    const addNote = () => {
      // {
      //   console.log(props.highlightAreas, "high");
      // }
      const note = {
        id: Date.now().toString(), // id: ++noteId,
        content: "manual", // message
        highlightAreas: props.highlightAreas,
        quote: props.selectedText,
        redactionType: redactiontype,
      };
      setNotes([note].concat(notes));
      props.cancel();
      // Store actions
      setActionlist([
        ...actionlist,
        {
          id: actionlist.length + 1,
          action: "highlighted",
          type: "undo",
          item: note,
        },
      ]);
    };

    return (
      <div
        style={{
          zIndex: 1,
          padding: "8px",
          background: "#fff",
          borderRadius: "2px",
          position: "absolute",
          border: "1px solid rgba(0, 0, 0, .3)",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        }}
      >
        {/*
        Text contet if needs to be submitted goes here
        <div>
          <textarea
            rows={3}
            style={{
              border: "1px solid rgba(0, 0, 0, .3)",
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        */}
        <div
          style={{
            display: "flex",
            marginTop: "8px",
          }}
        >
          <div style={{ marginRight: "8px" }}>
            <Button variant="outlined" onClick={addNote}>
              Add
            </Button>
          </div>
          <Button onClick={props.cancel}>Cancel</Button>
        </div>
      </div>
    );
  };

  const jumpToNote = (note) => {
    if (noteEles.has(note.id)) {
      noteEles.get(note.id).scrollIntoView();
    }
  };

  const renderHighlights = (props) => (
    <div>
      {notes.map((note, noteIndex) => (
        <React.Fragment key={`notesframe_${noteIndex}`}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={`div${idx}`}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4,
                  },
                  props.getCssProperties(area, props.rotation)
                )}
                onClick={() => jumpToNote(note)}
                ref={(ref) => {
                  noteEles.set(note.id, ref);
                }}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const removehighlight = (item, chk = "") => {
    var tempNotes = notes.filter((note) => {
      return note.id !== item.id;
    });
    setNotes(tempNotes);

    //  Store actions
    if (chk !== "no") {
      var removedNote = notes.filter((note) => {
        return note.id === item.id;
      })[0];
      setActionlist([
        ...actionlist,
        {
          id: actionlist.length + 1,
          action: "removed",
          type: "undo",
          item: removedNote,
        },
      ]);
    }
  };

  const chooseActions = (id) => {
    if (selectedlist.indexOf(id) > -1) {
      const temp = selectedlist.filter(function (no) {
        return no !== id;
      });
      setSelectedlist(temp);
    } else {
      setSelectedlist([...selectedlist, id]);
    }
  };

  const cancelList = () => {
    let temp = actionlist;
    let final = [];
    let tempNotes = notes;
    for (let i = 0; i < selectedlist.length; i++) {
      let id = selectedlist[i];
      var itm = temp.filter((listitm) => {
        return listitm.id === id;
      })[0];
      if (itm.action === "highlighted") {
        tempNotes = tempNotes.filter((note) => {
          return note.id !== itm.item.id;
        });
      }
      if (itm.action === "removed") {
        tempNotes = [itm.item].concat(tempNotes);
      }
      itm.type = itm.type === "undo" ? "redo" : "undo";
      itm.action = itm.action === "highlighted" ? "removed" : "highlighted";
      final.push(itm);
    }
    setNotes(tempNotes);
    var restArr = temp.filter((item) => {
      return selectedlist.indexOf(item.id) == -1;
    });
    setSelectedlist([...final, ...restArr]);
    setSelectedlist([]);
    setUndoredolist("none");
  };

  const cancelAll = (type) => {
    const chosen = actionlist.filter((x) => {
      return x.type === type;
    });
    const rest = actionlist.filter((y) => {
      return y.type !== type;
    });
    let final = [];
    let tempNotes = notes;
    let temp = actionlist;
    for (let i = 0; i < chosen.length; i++) {
      let single = chosen[i];
      var itm = temp.filter((listitm) => {
        return listitm.id === single.id;
      })[0];
      if (itm.action === "highlighted") {
        tempNotes = tempNotes.filter((note) => {
          return note.id !== itm.item.id;
        });
      }
      if (itm.action === "removed") {
        tempNotes = [itm.item].concat(tempNotes);
      }
      itm.type = itm.type === "undo" ? "redo" : "undo";
      itm.action = itm.action === "highlighted" ? "removed" : "highlighted";
      final.push(itm);
    }
    setNotes(tempNotes);
    setActionlist([...final, ...rest]);
    setSelectedlist([]);
    setUndoredolist("none");
  };

  const handleClickOutside = (event) => {
    if (droplistRef.current && !droplistRef.current.contains(event.target)) {
      setSelectedlist([]);
      setUndoredolist("none");
    }
  };

  useEffect(() => {
    getRedactions();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="topbar">
        <div className="buttonsframe">
          <div className="tooltipframe">
            <Button
              type="submit"
              variant="outlined"
              className="bluebtn"
              label="Save"
              onClick={() => onsave()}
            />
          </div>
          <div className="tooltipframe">
            <label>
              This is the output version used for internal review that shows
              proposed redactions: the text or area is still visible and
              searchable under the redaction boxes that can be seen when
              hovering over the redacted text/area. This version can be used to
              check the suggested redactions.
            </label>
            <Button
              type="submit"
              variant="outlined"
              className="bluebtn"
              label="Proposed"
              onClick={() => proposeddocument()}
            />
          </div>
          <div className="tooltipframe">
            <label>
              This is final output version that has final applied redactions.
              The version is intended for publication once approved. The
              redacted text is no longer searchable under the applied redaction
              boxes. The text or other content have been irrevocably and
              permanently deleted.
            </label>
            <Button
              type="submit"
              variant="outlined"
              className="bluebtn"
              label="Final"
              onClick={() => generatedocument()}
            />
          </div>
        </div>
        <div className="actionsframe">
          <div className="undoredoframe">
            <button
              className={
                actionlist.filter((k) => {
                  return k.type === "undo";
                }).length > 0
                  ? "undoredobtn"
                  : "undoredobtn disabled"
              }
              onClick={() => setUndoredolist("undo")}
            >
              <span className="undoredoicon">
                <Icon icon="undo" iconSize="xsmall" />
              </span>
              <span className="undoredoarrow">
                <Icon icon="keyboard_arrow_down" iconSize="xsmall" />
              </span>
            </button>
            {undoredolist === "undo" &&
              actionlist.filter((k) => {
                return k.type === "undo";
              }).length > 0 && (
                <ul ref={droplistRef}>
                  {actionlist.map((itm) => {
                    if (itm.type === "undo") {
                      return (
                        <li key={`liid_${itm.id}`}>
                          <a
                            onClick={() => chooseActions(itm.id)}
                            className={
                              selectedlist.indexOf(itm.id) > -1 ? "active" : ""
                            }
                          >
                            {itm.action} "{itm.item.quote}"
                          </a>
                        </li>
                      );
                    }
                  })}
                  {selectedlist.length > 0 && (
                    <li>
                      <a onClick={() => cancelList()}>
                        Clear {selectedlist.length} items
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={() => cancelAll("undo")}>Undo All</a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setSelectedlist([]);
                        setUndoredolist("none");
                      }}
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              )}
          </div>
          <span className="undoredoframe">
            <button
              className={
                actionlist.filter((k) => {
                  return k.type === "redo";
                }).length > 0
                  ? "undoredobtn"
                  : "undoredobtn disabled"
              }
              onClick={() => setUndoredolist("redo")}
            >
              <span className="undoredoicon">
                <Icon icon="redo" iconSize="xsmall" />
              </span>
              <span className="undoredoarrow">
                <Icon icon="keyboard_arrow_down" iconSize="xsmall" />
              </span>
            </button>
            {undoredolist === "redo" &&
              actionlist.filter((j) => {
                return j.type === "redo";
              }).length > 0 && (
                <ul ref={droplistRef}>
                  {actionlist.map((itm) => {
                    if (itm.type === "redo") {
                      return (
                        <li key={`liid_${itm.id}`}>
                          <a
                            onClick={() => chooseActions(itm.id)} //  undoredoFn(itm.id, "redo")
                            className={
                              selectedlist.indexOf(itm.id) > -1 ? "active" : ""
                            }
                          >
                            {itm.action} "{itm.item.quote}"
                          </a>
                        </li>
                      );
                    }
                  })}
                  {selectedlist.length > 0 && (
                    <li>
                      <a onClick={() => cancelList()}>
                        Redo {selectedlist.length} items
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={() => cancelAll("redo")}>Redo All</a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setSelectedlist([]);
                        setUndoredolist("none");
                      }}
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              )}
          </span>
        </div>
      </div>
      <div className="sidebar">
        <Card className="sidebardiv">
          <div className="redactions_frame">
            <h3>Redaction Tools</h3>
            <Sidebartabs
              notes={notes}
              // onsave={onsave}
              // undofn={undofn}
              // redofn={redofn}
              setnotes={setNotes}
              setloader={setLoader}
              documentid={documentId}
              redactiontype={redactiontype}
              requirementValue={requirement}
              removehighlight={removehighlight}
              resethighlights={resethighlights}
              // proposeddocument={proposeddocument}
              // generatedocument={generatedocument}
              settingredactiontype={setRedactiontype}
              jumptohighlightarea={jumpToHighlightArea}
            />
          </div>
        </Card>
        <Card
          variant="raised"
          className="pdfviewerframe"
          bodyAlignment="left-center"
          style={{
            position: "relative",
            background: "none",
            boxShadow: "none",
          }}
        >
          <Navbarreviews createdAt={createdAt} documentName={documentName} />
          <div className="viewerframe">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer
                fileUrl={fileUrl}
                plugins={[
                  highlightPluginInstance,
                  defaultLayoutPluginInstance,
                  selectionModePluginInstance,
                ]}
              />
            </Worker>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Highlightexample;
