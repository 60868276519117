import React, { useState, useRef, useEffect } from "react";
import { Button, Icon, NotificationBadge } from "@element/react-components";
import Notificationimg from "../assets/Notifications.svg";
import Pendingimg from "../assets/pending.svg";
import Hourglassimg from "../assets/hourglass.svg";
import { basePath } from "../utils/constants";
import "../styles/notifications.scss";

const Notifications = (props) => {
  const wrapperRef = useRef(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [messages, setMessages] = useState([
    // { id: 129088, status: "completed" },
    // { id: 129078, status: "pending" },
    // { id: 123088, status: "completed" },
    // { id: 129038, status: "pending" },
  ]);

  const removeTicket = (id) => {
    let temp = messages.filter((item) => {
      return item.documentId !== id;
    });
    setMessages(temp);
  };

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !showNotifications
    ) {
      setShowNotifications(false);
    }
  };

  const loadDocumentList = () => {
    const headers = {
      "Content-Type": "application/json",
      //"Ocp-Apim-Subscription-Key": "e6888526d28b46d598dc68f1e210e15d",
    };
    fetch(`${basePath}/document/`, { headers })
      .then((response) => response.json())
      .then((jsonArr) => {
        const results = jsonArr.slice(0, 5);
        setMessages(results);
      })
      .finally(() => {
        // console.log("done");
      });
  };

  useEffect(() => {
    loadDocumentList();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  return (
    <>
      <a
        ref={wrapperRef}
        className="notifyframe"
        onClick={() => setShowNotifications(true)}
      >
        {/* {messages.length > 0 ? (
          <span
            className="unreadmsgs"
            onClick={() => setShowNotifications(true)}
          >
            {messages.length}
          </span>
        ) : (
          ""
        )} */}
        {messages.length > 0 ? (
          <Icon
            {...props}
            badge={<NotificationBadge counter={messages.length} />}
            icon={<img alt="Custom SVG" src={Notificationimg} />}
          />
        ) : (
          <Icon
            {...props}
            icon={<img alt="Custom SVG" src={Notificationimg} />}
          />
        )}
        {/* <img src={Notificationimg} alt="Notification" /> */}
        {showNotifications ? (
          <ul className="notifications">
            {messages.length > 0 &&
              messages.map((item, i) => (
                <li key={i}>
                  <span className="notificationicon">
                    {item.aiRedacted ? (
                      <img src={Hourglassimg} alt="success" />
                    ) : (
                      <img src={Pendingimg} alt="pending" />
                    )}
                  </span>
                  <span
                    className="notificationtext"
                    title={
                      item.aiRedacted
                        ? "Redacted Successfully"
                        : "Redaction Failed"
                    }
                  >
                    {item.documentName.length > 21
                      ? item.documentName.substring(0, 21) + "..."
                      : item.documentName}
                  </span>
                  <span
                    className="notificationclose"
                    onClick={() => removeTicket(item.documentId)}
                  >
                    X
                  </span>
                </li>
              ))}
            {messages.length === 0 ? (
              <li className="nomsg">You have cleared all the notifications</li>
            ) : (
              ""
            )}
            {messages.length > 0 ? (
              <li>
                <button
                  className="btnclearnotify active"
                  onClick={() => setMessages([])}
                >
                  Clear all notification
                </button>
              </li>
            ) : (
              <li>
                <button className="btnclearnotify disabled" disabled>
                  Clear all notification
                </button>
              </li>
            )}
          </ul>
        ) : (
          ""
        )}
      </a>
    </>
  );
};

export default Notifications;
