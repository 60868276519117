import React, { useState } from "react";
import { Button, Textfield } from "@element/react-components";

import { basePath, httpHeaders } from "../utils/constants";
import Loader from "./Loader";
import axios from "axios";

const Editcomment = (props) => {
  const [loader, setLoader] = useState(false);
  const [ipvalues, setIpvalues] = useState({
    comments: "",
  });

  const submitCommentBtn = () => {
    setLoader(true);
    const data = {
      user: props.username,
      comments: ipvalues.comments,
    };
    try {
      axios({
        url: `${basePath}/document/${props.editdata.documentId}/`,
        method: "PUT",
        headers: httpHeaders,
        data: data,
      })
        .then(function (response) {
          props.closePopup();
          props.loadDocumentListFn();
          setLoader(false);
          props.settingerrorstatus({
            flag: true,
            type: "success",
            message: "Success! Comment has been updated",
          });
        })
        .catch((err) => {
          props.closePopup();
          props.settingerrorstatus({
            flag: true,
            type: "error",
            message: `Error: ${err}`,
          });
          setLoader(false);
        });
    } catch (error) {
      props.closePopup();
      setLoader(false);
      props.settingerrorstatus({
        flag: true,
        type: "error",
        message: `Error: ${error}`,
      });
    }
  };

  useState(() => {
    setIpvalues({ ...ipvalues, comments: props.editdata.comments });
  }, []);

  return (
    <>
      {loader ? <Loader /> : ""}
      <h2 className="blackheading">Edit user comments</h2>
      <div className="formsingleelm">
        <Textfield
          id="commentsId"
          name="comments"
          variant="outlined"
          label="Comment*"
          style={{ width: "100%" }}
          value={ipvalues.comments}
          placeholder="Enter comment"
          onChange={(e) =>
            setIpvalues({ ...ipvalues, comments: e.target.value })
          }
        />
      </div>
      <div className="formsingleelm btnframeleft">
        <Button
          type="submit"
          label="CANCEL"
          variant="outlined"
          onClick={() => props.closePopup()}
          style={{
            backgroundColor: "#fff",
            borderColor: "#000",
            color: "#000",
          }}
        />
        &nbsp;&nbsp;&nbsp;
        <Button
          type="submit"
          label="OK"
          variant="outlined"
          onClick={() => submitCommentBtn()}
          style={{
            backgroundColor: "#40BAFF",
            borderColor: "#000",
            color: "#000",
          }}
        />
      </div>
    </>
  );
};

export default Editcomment;
