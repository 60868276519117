import "../styles/Metric.scss";

const Metric = (props) => {
  return (
    <>
      {props.data.map((itm, i) => {
        return (
          <div className="metricframe" key={`listdata${i}`}>
            <div className="metrictitle">{itm.title}</div>
            <div className="metriccaption">Total : {itm.count}</div>
            <div className="metriccontent">
              <div className="count">
                <h4>{itm.data1}</h4>
              </div>
              <div className="data">
                <div
                  className="data1"
                  style={{ backgroundColor: "#66b512", width: itm.data1 }}
                ></div>
                <div
                  className="data2"
                  style={{ backgroundColor: "#d30f4b", width: itm.data2 }}
                ></div>
              </div>
              <div className="count">
                <h4>{itm.data2}</h4>
              </div>
            </div>
          </div>
        );
      })}
      <div className="colorscheme">
        <span
          className="schemeblock"
          style={{ backgroundColor: "#66b512" }}
        ></span>
        <span className="schemetext">Reviews {props.data[0].label1}</span>
        <span
          className="schemeblock"
          style={{ backgroundColor: "#d30f4b" }}
        ></span>
        <span className="schemetext">Reviews {props.data[0].label2}</span>
      </div>
    </>
  );
};

export default Metric;
