export const basePath =
  "https://aml-redact-backend-app-03.azurewebsites.net/api";
// "https://aml-redact-backend-app-02.azurewebsites.net/api";

export const httpHeaders = {
  "Content-Type": "application/json",
  //  "Ocp-Apim-Subscription-Key": "e6888526d28b46d598dc68f1e210e15d",
};

export const httpHeadersUpload = {
  "Content-Type": "multipart/form-data",
  //  "Ocp-Apim-Subscription-Key": "e6888526d28b46d598dc68f1e210e15d",
};

export const requirementList = [
  "EU-CTR",
  "EU-PAS",
  "Other",
  "FOI request",
  "Journal Redaction",
  "Voluntary disclosure",
  "NIH Final rule (CT.gov)",
  "More requirements to be added later",
  // "HC-PRCI",
  // "EMA-Policy-0070",
  // "RA Assessment Report",
  // "More marking styles to be added later",
];

export const redactionVariantList = [
  "For Internal Review",
  "For Publication",
  "Not for Publication",
];

export const rulesSetList = [
  // "NIH / EU PASS / EU CTR Version 2.0_27MAR2024",
  "Bayer PPD redaction-only ruleset for NIH / EU PASS / EU CTR Version 2.0_27MAR2024",
  "Other",
];

export const restrictionList = ["Public", "Private"];

export const statusList = [
  "Draft",
  "Unprocessed",
  "For Review",
  "For Approval",
  "Approved",
  "Reviewed",
];

export const redactioninfoList = ["PPD", "CCI", "Both"];

export const markingStyleList = [
  "Standard Bayer Style - (EMA style)",
  "More marking styles to be added later",
];

export const modifiedPeriodList = [
  { text: "Today", value: "Today" },
  { text: "Yesterday", value: "Yesterday" },
  { text: "This week", value: "This week" },
  { text: "Last week", value: "Last week" },
  { text: "This month", value: "This month" },
  { text: "Last month", value: "Last month" },
  { text: "This year", value: "This year" },
  { text: "Last year", value: "Last year" },
];
