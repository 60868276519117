import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { basePath, httpHeaders } from "../utils/constants";
import "../styles/navbarreviews.scss";

const Navbarreviews = ({ documentName, createdAt }) => {
  const params = useParams();
  const docId = params.id;
  const [data, setData] = useState([]);
  const [dataMod, setDataMod] = useState();
  useEffect(() => {
    fetch(fetch(`${basePath}/document/${docId}/`, { httpHeaders }))
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      });
  }, []);

  return (
    <div className="navheaders">
      <p>{documentName}</p>
    </div>
  );
};

export default Navbarreviews;
