import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Icon,
  Button,
  // Select,
} from "@element/react-components";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import {
  useGlobalFilter,
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useColumnOrder,
  // useAsyncDebounce,
} from "react-table";
import { Link } from "react-router-dom";
import Documentfilter from "../components/Documentfilter";
import Editdocuments from "../components/Editdocuments";
import Editcomment from "../components/Editcomment";
import Editstatus from "../components/Editstatus";

import upIcon from "../assets/up.svg";
import downIcon from "../assets/down.svg";
import Search from "../assets/Search.svg";
import Filter from "../assets/filter.svg";
import Moreactions from "../assets/Moreactions.svg";
// import tbldataData from "../assets/tbldata.json";
import Uploadfile from "../components/Uploadfile";
import { basePath, httpHeaders } from "../utils/constants";
import Loader from "../components/Loader";
import Confirmation from "../components/Confirmation";

import "../styles/List.scss";

const List = () => {
  const [loader, setLoader] = useState(false);
  const [tbldata, setTbldata] = useState([]);
  const [alltbldata, setAlltbldata] = useState([]);
  const [username, setUsername] = useState("");
  const [openModal, setOpenModal] = useState({
    show: false,
    type: "",
  });
  const [deleteid, setDeleteid] = useState(0);
  const [openConfirm, setOpenConfirm] = useState({
    show: false,
    type: "",
    warning: "",
    message: "",
  });
  const [errorstatus, setErrorstatus] = useState({
    flag: false,
    type: "",
    message: "",
  });
  const [srchWidth, setSrchWidth] = useState(17);
  const fieldList = [
    { text: "All", value: "" },
    { text: "Document Name", value: "documentName" },
    { text: "Redaction Requirement", value: "requirement" },
    { text: "Redaction Variant", value: "redactionVariant" },
    { text: "Study Drug", value: "studyDrug" },
    { text: "Study Number", value: "studyNumbers" },
    { text: "Project Id", value: "projectId" },
    { text: "Restriction", value: "restriction" },
    { text: "Created By", value: "createdBy" },
    { text: "Last Modified By", value: "modifiedBy" },
    { text: "Status", value: "status" },
    { text: "User Comment", value: "comments" },
  ];
  const [srchtype, setSrchtype] = useState({ text: "All", value: "" });

  const emptyFilterValue = {
    type: [],
    users: [],
    status: [],
    restriction: [],
    studyNumbers: [],
    redactionVariant: [],
    requirement: [],
  };
  const [defaultfilterValue, setDefaultfilterValue] =
    useState(emptyFilterValue);

  const [editdata, setEditdata] = useState({});
  const searchtextref = useRef();

  const isLoggedIn = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const currentAccount = accounts[0];

  const loadDocumentList = () => {
    setLoader(true);
    fetch(`${basePath}/document/`, { httpHeaders })
      .then((response) => response.json())
      .then((json) => {
        setTbldata(json);
        setAlltbldata(json);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const actionsMenu = (type, row) => {
    setEditdata(row.original);
    if (type === "edit") {
      setOpenConfirm({
        show: true,
        type: "edit",
        // warning: "This will edit the current document properties and cannot be undone.",
        message: "Are you sure you want to edit the document properties?",
      });
    } else if (type === "delete") {
      setDeleteid(row.original.documentId);
      setOpenConfirm({
        show: true,
        type: "delete",
        // warning: "This will delete the current document and cannot be undone.",
        message: "Are you sure you want to delete the document?",
      });
    } else if (type === "comment") {
      setOpenConfirm({
        show: true,
        type: "comment",
        // warning: "This will edit the comment and cannot be undone.",
        message: "Are you sure you want to update comment of this document?",
      });
    } else if (type === "status") {
      setOpenConfirm({
        show: true,
        type: "status",
        // warning: "This will edit the status and cannot be undone.",
        message: "Are you sure you want to update status of this document?",
      });
    }
  };

  const confirmedAction = (type) => {
    setOpenConfirm({
      show: false,
      type: "",
      warning: "",
      message: "",
    });
    if (type === "edit") {
      setOpenModal({
        show: true,
        type: "editdoc",
      });
    } else if (type === "delete") {
      deletehandler(deleteid);
    } else if (type === "comment") {
      setOpenModal({
        show: true,
        type: "editcomment",
      });
    } else if (type === "status") {
      setOpenModal({
        show: true,
        type: "editstatus",
      });
    }
  };

  const confirmedCancel = () => {
    setOpenModal({
      show: false,
      type: "",
    });
    setOpenConfirm({
      show: false,
      type: "",
      warning: "",
      message: "",
    });
  };

  const closePopup = () => {
    setOpenModal({
      show: false,
      type: "",
    });
  };

  const deletehandler = async (docId) => {
    setLoader(true);
    try {
      const response = await axios({
        url: `${basePath}/document/${docId}/`,
        method: "delete",
        headers: httpHeaders,
      });
      if (response.status === 204) {
        loadDocumentList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchInList = (searchVal) => {
    let temp = alltbldata,
      results = [];
    for (var i = 0; i < temp.length; i++) {
      if (
        srchtype.value === "" &&
        (temp[i].documentName.toLowerCase().indexOf(searchVal.toLowerCase()) >
          -1 ||
          temp[i].requirement.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].redactionVariant
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          temp[i].studyDrug.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].studyNumbers.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].projectId.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].restriction.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].createdBy.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].modifiedBy.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].status.toLowerCase().indexOf(searchVal.toLowerCase()) > -1 ||
          temp[i].comments.toLowerCase().indexOf(searchVal.toLowerCase()) >
            -1 ||
          temp[i].filePath.toLowerCase().indexOf(searchVal.toLowerCase()) > -1)
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "documentName" &&
        temp[i].documentName.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "requirement" &&
        temp[i].requirement.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "redactionVariant" &&
        temp[i].redactionVariant
          .toLowerCase()
          .indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "studyDrug" &&
        temp[i].studyDrug.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "studyNumbers" &&
        temp[i].studyNumbers.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "projectId" &&
        temp[i].projectId.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "restriction" &&
        temp[i].restriction.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "createdBy" &&
        temp[i].createdBy.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "modifiedBy" &&
        temp[i].modifiedBy.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "status" &&
        temp[i].status.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      } else if (
        srchtype.value === "comments" &&
        temp[i].comments.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
      ) {
        results.push(temp[i]);
      }
    }
    setTbldata(results);
  };

  const handleClickOutside = (e) => {
    if (!searchtextref.current.contains(e.target)) {
      setSrchWidth(17);
    }
  };

  const filtersubmit = async (obj) => {
    setDefaultfilterValue(obj);
    try {
      axios({
        url: `${basePath}/filter_documents/`,
        method: "POST",
        headers: httpHeaders,
        data: obj,
      })
        .then(function (response) {
          setTbldata(response.data);
          setAlltbldata(response.data);
        })
        .catch((err) => {
          setErrorstatus({
            flag: true,
            type: "error",
            message: `Error: ${err}`,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const emptyFilterValuesfn = () => {
    setDefaultfilterValue(emptyFilterValue);
  };

  //  Table starts
  let tblcolumns = [
    {
      id: "documentName",
      header: "Document Name",
      Cell: ({ row }) => {
        return (
          <>
            <Link to={`/reviews/${row.original.documentId}`}>
              {row.original.documentName}
            </Link>
          </>
        );
      },
    },
    {
      header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="more_actions">
            <Button
              leadingIcon={<img src={Moreactions} alt="Toggle colums" />}
              variant="text"
              fullWidth={true}
              themeColor="on-unknown-white"
            />
            <ul>
              <li>
                <a onClick={() => actionsMenu("edit", row)}>Edit</a>
              </li>
              <li>
                <a onClick={() => actionsMenu("delete", row)}>Delete</a>
              </li>
              <li>
                <a onClick={() => actionsMenu("comment", row)}>
                  Update Comment
                </a>
              </li>
              <li>
                <a onClick={() => actionsMenu("status", row)}>Update Status</a>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      header: "Study Drug(s)",
      accessor: "studyDrug",
    },
    {
      header: "Study Number(s)",
      accessor: "studyNumbers",
    },
    {
      header: "Status",
      accessor: "status",
    },
    // {
    //   header: "Redacted Info",
    //   accessor: "uploadFileType",
    // },
    {
      header: "Redaction Requirement",
      accessor: "requirement",
    },
    {
      header: "Redaction Variant",
      accessor: "redactionVariant",
    },
    {
      header: "Last Modified By",
      id: "modifiedBy",
      displayType: "date",
      sortable: true,
      accessor: (d) => {
        return `${d.modifiedBy} on ${new Date(
          d.modifiedAt
        ).toLocaleDateString()}`;
      },
    },
    {
      header: "Restriction",
      accessor: "restriction",
    },
    {
      header: "Created By",
      id: "createdBy",
      displayType: "date",
      sortable: true,
      accessor: (e) => {
        return `${e.createdBy} on ${new Date(
          e.createdAt
        ).toLocaleDateString()}`;
      },
    },
    {
      header: "Format",
      id: "formatID",
      accessor: (e) => {
        return e.filePath.split(".").pop().toUpperCase();
      },
    },
    {
      header: "Project Id",
      accessor: "projectId",
    },
    {
      header: "User Comments",
      id: "commentsId",
      accessor: (e) => {
        return e.comments === "" ? "No comments" : e.comments;
      },
    },
  ];
  const columns = useMemo(() => tblcolumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    // getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data: tbldata,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useColumnOrder,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return columns;
      });
    }
  );
  const { pageIndex, pageSize } = state;
  //  Table ends

  useEffect(() => {
    // console.log(currentAccount);
    setUsername(currentAccount.name);
    loadDocumentList();

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <>
        {loader ? <Loader /> : ""}
        <Card
          variant="raised"
          height="auto"
          bodyAlignment="left-center"
          style={{
            width: "96vw",
            display: "flex",
            marginTop: "20px",
            alignItems: "center",
            padding: "0.75rem 20px",
          }}
        >
          <h2 className="blueheading">Upload Files</h2>
          <p>click the button below to upload a new document </p>
          <CardBody
            style={{
              alignItems: "center",
              flexWrap: "wrap",
              display: "flex",
              width: "100%",
            }}
          >
            <Uploadfile
              loadDocumentListFn={loadDocumentList}
              settingerrorstatus={setErrorstatus}
              username={username}
            />
          </CardBody>
        </Card>
        <Card
          variant="raised"
          height="auto"
          bodyAlignment="left-center"
          style={{
            width: "96vw",
            display: "flex",
            marginTop: "20px",
            marginBottom: "20px",
            alignItems: "center",
            padding: "0.75rem 0",
          }}
        >
          <CardBody>
            <div className="documentist">
              <div className="documentlistheading">
                <div className="doc_tblheadline">Documents List</div>
                <div className="doc_tblfilterbtn">
                  <button
                    onClick={() =>
                      setOpenModal({
                        show: true,
                        type: "filter",
                      })
                    }
                  >
                    <img src={Filter} alt="Filter" width="18" />
                  </button>
                  {/*
                  Un comment to get the filters working
                  <div className="doc_tblfiltertype">
                    <Select
                      dense
                      width="150px"
                      id="fieldValId"
                      value={srchtype}
                      variant="outlined"
                      options={fieldList}
                      label="Select search fields"
                      onChange={(e) => setSrchtype(e)}
                    />
                  </div>
                  Un comment to get the filters working
                  */}
                  <input
                    type="text"
                    name="searchtxt"
                    autoComplete="off"
                    ref={searchtextref}
                    className="searchtxt"
                    onClick={() => setSrchWidth(240)}
                    onChange={(e) => searchInList(e.target.value)}
                    placeholder={srchWidth === 17 ? "" : "Search here"}
                    style={{
                      backgroundImage: `url(${Search})`,
                      width: `${srchWidth}px`,
                    }}
                  />
                </div>
              </div>
              <div className="tableframe">
                <table {...getTableProps()} className="table">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((columns) => (
                          <th
                            {...columns.getHeaderProps(
                              columns.getSortByToggleProps()
                            )}
                          >
                            <div className="th_head">
                              <div>{columns.render("header")}</div>
                              <div className="updownarrows">
                                {columns.isSorted ? (
                                  columns.isSortedDesc ? (
                                    <Icon
                                      icon={
                                        <img
                                          alt="down"
                                          src={downIcon}
                                          width="10"
                                        />
                                      }
                                    />
                                  ) : (
                                    <Icon
                                      icon={
                                        <img alt="up" src={upIcon} width="10" />
                                      }
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="paginationFrame">
                <span>Show&nbsp;</span>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                <span>
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {"|<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">|"}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
        {errorstatus.flag && (
          <div
            className={
              errorstatus.type === "error"
                ? "toastmessage toasterror"
                : errorstatus.type === "success"
                ? "toastmessage toastsuccess"
                : errorstatus.type === "warning"
                ? "toastmessage toastwarning"
                : "toastmessage toasterror"
            }
          >
            <div>
              {errorstatus.message}{" "}
              <button
                onClick={() =>
                  setErrorstatus({
                    flag: false,
                    type: "",
                    message: "",
                  })
                }
              >
                DISMISS
              </button>
            </div>
          </div>
        )}
        {openModal.show && (
          <div className="custommodal">
            {openModal.type === "filter" && (
              <div className="custommodalbody">
                <Documentfilter
                  loadDocumentListFn={loadDocumentList}
                  defaultfilterValue={defaultfilterValue}
                  emptyFilterValuesfn={emptyFilterValuesfn}
                  onfiltersubmit={filtersubmit}
                  modalDisplay={setOpenModal}
                  setLoader={setLoader}
                />
              </div>
            )}
            {openModal.type === "editdoc" && (
              <div className="custommodalbody">
                <Editdocuments
                  username={username}
                  loadDocumentListFn={loadDocumentList}
                  editdata={editdata}
                  closePopup={closePopup}
                  settingerrorstatus={setErrorstatus}
                />
              </div>
            )}
            {openModal.type === "editcomment" && (
              <div className="customsmallmodalbody">
                <Editcomment
                  editdata={editdata}
                  username={username}
                  closePopup={closePopup}
                  settingerrorstatus={setErrorstatus}
                  loadDocumentListFn={loadDocumentList}
                />
              </div>
            )}
            {openModal.type === "editstatus" && (
              <div className="customsmallmodalbody">
                <Editstatus
                  editdata={editdata}
                  username={username}
                  closePopup={closePopup}
                  settingerrorstatus={setErrorstatus}
                  loadDocumentListFn={loadDocumentList}
                />
              </div>
            )}
          </div>
        )}
        {openConfirm.show && (
          <>
            <Confirmation
              message={openConfirm.message}
              error={openConfirm.warning}
              cancelfn={confirmedCancel}
              submitfn={confirmedAction}
              type={openConfirm.type}
            />
          </>
        )}
      </>
    );
  }
};

export default List;
