import React, { useState, useEffect } from "react";
import axios from "axios";
import { Checkbox } from "@element/react-components";
import {
  redactionVariantList,
  requirementList,
  statusList,
  basePath,
  httpHeaders,
} from "../utils/constants";
import Search from "../assets/Search.svg";
import "../styles/documentfilter.scss";

const Documentfilter = (props) => {
  const [listofusers, setListofusers] = useState([]);
  const [autolistofusers, setAutolistofusers] = useState([]);

  const [usersearchtext, setUsersearchtext] = useState("");
  const [autocompletelist, setAutocompletelist] = useState(false);
  const [studybumbertext, setStudybumbertext] = useState("");
  const [filterType, setFilterType] = useState(props.defaultfilterValue);

  const modalCheckboxClick = (flag, type, value) => {
    if (type === "restriction") {
      let temp = filterType.restriction;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, restriction: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, restriction: newarr });
      }
    }
    if (type === "type") {
      let temp = filterType.type;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, type: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, type: newarr });
      }
    }
    if (type === "requirement") {
      let temp = filterType.requirement;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, requirement: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, requirement: newarr });
      }
    }
    if (type === "redactionVariant") {
      let temp = filterType.redactionVariant;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, redactionVariant: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, redactionVariant: newarr });
      }
    }
    if (type === "status") {
      let temp = filterType.status;
      if (temp.indexOf(value) < 0 && flag) {
        temp.push(value);
        setFilterType({ ...filterType, status: temp });
      } else if (temp.indexOf(value) >= 0 && !flag) {
        let newarr = temp.filter((itm) => itm !== value);
        setFilterType({ ...filterType, status: newarr });
      }
    }
    // console.log(filterType);
  };

  const removeFromCheckbox = (type, value) => {
    if (type === "redactionVariant") {
      let temp = filterType.redactionVariant;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, redactionVariant: newarr });
    }
    if (type === "restriction") {
      let temp = filterType.restriction;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, restriction: newarr });
    }
    if (type === "type") {
      let temp = filterType.type;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, type: newarr });
    }
    if (type === "requirement") {
      let temp = filterType.requirement;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, requirement: newarr });
    }
    if (type === "status") {
      let temp = filterType.status;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, status: newarr });
    }
    if (type === "users") {
      let temp = filterType.users;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, users: newarr });
    }
    if (type === "studyNumbers") {
      let temp = filterType.studyNumbers;
      let newarr = temp.filter((itm) => itm !== value);
      setFilterType({ ...filterType, studyNumbers: newarr });
    }
  };

  const listUsersforFilter = (text) => {
    let temp = autolistofusers;
    const results = temp.filter((element) =>
      element.toLowerCase().includes(text)
    );
    console.log(text, temp, results);
    setUsersearchtext(text);
    setListofusers(results);
    setAutocompletelist(true);
  };

  const typeStudyNumber = (value) => {
    setStudybumbertext(value);
  };

  const searchStudyNumber = (key) => {
    if (key === "Enter") {
      let temp = filterType.studyNumbers;
      if (temp.indexOf(studybumbertext) < 0) {
        temp.push(studybumbertext);
        setFilterType({ ...filterType, studyNumbers: temp });
        setStudybumbertext("");
      }
    }
  };

  const selectFilterUsers = (value) => {
    let temp = filterType.users;
    if (temp.indexOf(value) < 0) {
      temp.push(value);
      setFilterType({ ...filterType, users: temp });
    }
    setUsersearchtext("");
    setAutocompletelist(false);
  };

  const onCancel = () => {
    props.emptyFilterValuesfn();
    props.loadDocumentListFn();
    props.modalDisplay({
      show: false,
      type: "",
    });
  };

  const finalSubmit = () => {
    props.onfiltersubmit(filterType);
    props.modalDisplay({
      show: false,
      type: "",
    });
  };

  const loadUsers = async () => {
    props.setLoader(true);
    try {
      await axios
        .get(`${basePath}/get_users`, { httpHeaders })
        .then((response) => {
          setListofusers(response.data);
          setAutolistofusers(response.data);
          props.setLoader(false);
        })
        .catch((err) => {
          props.setLoader(false);
        });
    } catch (error) {
      console.error(error);
      props.setLoader(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      <div className="documentfilter">
        <div className="documentfilterdiv">
          <div className="documentfiltersection">
            <h3>Select User</h3>
            <div className="searchtext">
              <input
                type="text"
                value={usersearchtext}
                placeholder="Search User"
                onChange={(e) => listUsersforFilter(e.target.value)}
                style={{
                  backgroundImage: `url(${Search})`,
                }}
              />
              {autocompletelist ? (
                <ul>
                  {listofusers.map((item, index) => {
                    return (
                      <li
                        onClick={() => {
                          selectFilterUsers(item);
                        }}
                        key={`listofusers_${index}`}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.users.map((itm, i) => {
                  return (
                    <li key={`user_${i}`}>
                      &#10004; {itm}{" "}
                      <span onClick={() => removeFromCheckbox("users", itm)}>
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="documentfiltersection">
            <h3>Select Redaction Variant</h3>
            <div className="checkboxframes">
              {redactionVariantList.map((redactvarItm, i) => {
                return (
                  <Checkbox
                    label={redactvarItm}
                    id={`chkframe_${i}`}
                    key={`chkframeKey_${i}`}
                    checked={
                      filterType.redactionVariant.indexOf(redactvarItm) < 0
                        ? false
                        : true
                    }
                    onChange={(e) =>
                      modalCheckboxClick(e, "redactionVariant", redactvarItm)
                    }
                  />
                );
              })}
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.redactionVariant.map((itm, i) => {
                  return (
                    <li key={`redactionVariant_${i}`}>
                      &#10004; {itm}{" "}
                      <span
                        onClick={() =>
                          removeFromCheckbox("redactionVariant", itm)
                        }
                      >
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="documentfilterdiv">
          <div className="documentfiltersection">
            <h3>Select Restriction</h3>
            <div className="checkboxframes">
              <Checkbox
                label="Private"
                checked={
                  filterType.restriction.indexOf("Private") < 0 ? false : true
                }
                onChange={(e) =>
                  modalCheckboxClick(e, "restriction", "Private")
                }
              />
              <Checkbox
                label="Public"
                checked={
                  filterType.restriction.indexOf("Public") < 0 ? false : true
                }
                onChange={(e) => modalCheckboxClick(e, "restriction", "Public")}
              />
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.restriction.map((itm, i) => {
                  return (
                    <li key={`restriction_${i}`}>
                      &#10004; {itm}{" "}
                      <span
                        onClick={() => removeFromCheckbox("restriction", itm)}
                      >
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="documentfiltersection">
            <h3>Select Study Number</h3>
            <div className="searchtext">
              <input
                type="text"
                value={studybumbertext}
                placeholder="Search Study Number"
                onChange={(e) => typeStudyNumber(e.target.value)}
                onKeyDown={(e) => searchStudyNumber(e.key)}
                style={{
                  backgroundImage: `url(${Search})`,
                }}
              />
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.studyNumbers.map((itm, i) => {
                  return (
                    <li key={`user_${i}`}>
                      &#10004; {itm}{" "}
                      <span
                        onClick={() => removeFromCheckbox("studyNumbers", itm)}
                      >
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="documentfiltersection">
            <h3>Select Redaction Info</h3>
            <div className="checkboxframes">
              <Checkbox
                label="PPD"
                checked={filterType.type.indexOf("PPD") < 0 ? false : true}
                onChange={(e) => modalCheckboxClick(e, "type", "PPD")}
              />
              <Checkbox
                label="CCI"
                checked={filterType.type.indexOf("CCI") < 0 ? false : true}
                onChange={(e) => modalCheckboxClick(e, "type", "CCI")}
              />
              <Checkbox
                label="Both"
                checked={filterType.type.indexOf("Both") < 0 ? false : true}
                onChange={(e) => modalCheckboxClick(e, "type", "Both")}
              />
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.type.map((itm, i) => {
                  return (
                    <li key={`type_${i}`}>
                      &#10004; {itm}{" "}
                      <span onClick={() => removeFromCheckbox("type", itm)}>
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="documentfilterdiv">
          <div className="documentfiltersection">
            <h3>Select Redaction Requirement</h3>
            <div className="checkboxframes">
              {requirementList.map((reqItm, i) => {
                return (
                  <Checkbox
                    key={`chkfr_${i}`}
                    label={reqItm}
                    checked={
                      filterType.requirement.indexOf(reqItm) < 0 ? false : true
                    }
                    onChange={(e) =>
                      modalCheckboxClick(e, "requirement", reqItm)
                    }
                  />
                );
              })}
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.requirement.map((itm, i) => {
                  return (
                    <li key={`requirement_${i}`}>
                      &#10004; {itm}{" "}
                      <span
                        onClick={() => removeFromCheckbox("requirement", itm)}
                      >
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="documentfiltersection">
            <h3>Select Status</h3>
            <div className="checkboxframes">
              {statusList.map((statusItm, i) => {
                return (
                  <Checkbox
                    label={statusItm}
                    key={`statuss_${i}`}
                    checked={
                      filterType.status.indexOf(statusItm) < 0 ? false : true
                    }
                    onChange={(e) => modalCheckboxClick(e, "status", statusItm)}
                  />
                );
              })}
            </div>
            <div className="checkboxvaluelist">
              <ul>
                {filterType.status.map((itm, i) => {
                  return (
                    <li key={`status_${i}`}>
                      &#10004; {itm}{" "}
                      <span onClick={() => removeFromCheckbox("status", itm)}>
                        &#10006;
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="filterbuttonframe buttonframe">
        <button onClick={() => onCancel()} className="white">
          Cancel
        </button>
        <button onClick={() => finalSubmit()} className="blue">
          Submit
        </button>
      </div>
    </>
  );
};

export default Documentfilter;
