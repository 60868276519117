import { Routes, Route, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./ui-components/PageLayout";
// import { Profile } from "./Pages/Profile";
// import Reviews from "./ui-components/Reviews";
// import Documents from "./ui-components/Documents";
import List from "./Pages/List";
import { Home } from "./Pages/Home";
import { Logout } from "./Pages/Logout";
import Dashboard from "./ui-components/Dashboard";
import RedactionsAndReviews from "./ui-components/RedactionsAndReviews";

import "./styles/App.scss";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <Grid container justifyContent="center">
          <Pages />
        </Grid>
      </PageLayout>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      {/* <Route path="/documents" element={<Documents />} /> */}
      <Route path="/documents" element={<List />} />
      <Route path="/reviews/:id" element={<RedactionsAndReviews />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default App;
